@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/static/fonts/MaterialIcons/MaterialIcons-Regular.ttf)
    format("truetype");
}

/* Inter */
@font-face {
  font-family: "Inter";
  src: url(/static/fonts/Inter/static/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url(/static/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url(/static/fonts/Inter/static/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url(/static/fonts/Inter/static/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* NotoSansKR */
@font-face {
  font-family: "Noto Sans KR";
  src: url(/static/fonts/NotoSansKR/NotoSansKR-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(/static/fonts/NotoSansKR/NotoSansKR-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(/static/fonts/NotoSansKR/NotoSansKR-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(/static/fonts/NotoSansKR/NotoSansKR-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}
